import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import AutomaticImg from '../../../assets/images/automatic-gates.jpg'
import AutomaticImg2 from '../../../assets/images/residential-img.jpg'

class AutomaticGates extends React.Component {
	render() {
		const siteTitle =
			'Automatic Gates - Arlington, TX and Dallas-Fort Worth, TX'
		const siteDescription =
			'Automatic Gate Installation and Services in Arlington, TX. Call  for Free Estimates on Installs and Gate Repair in Dallas-Fort Worth'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Automatic Gates in Arlington, TX</h1>
						<hr />
					</div>
				</div>

				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-8">
							<p>
								If you’re shopping for a{' '}
								<Link to="/gates/services/residential-services">
									residential
								</Link>{' '}
								automatic gate or automated commercial security gate in
								Dallas-Fort Worth, you’ve probably been overwhelmed by all the
								options available.
							</p>
							<p>
								There are many <Link to="/gates">types of gates</Link> on the
								market, but for privacy and security reasons, automatic gates
								are hard to beat, making them a wise investment for almost
								everyone. In addition to providing the security you need, they
								can also add to the appearance and value of your property. To
								help you make the right decisions in your gate selection process
								turn to us. Contact us at for automatic gates in Arlington, TX.
							</p>
						</div>
						<div className="col-md-4 mt-4">
							<LazyLoadImage
								className="img-fluid"
								src={AutomaticImg}
								alt="Automatic Gate"
							/>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row mb-5">
						<div className="col-md-12">
							<h4>Automatic Gate Systems</h4>
							<p>
								We can help you choose a remote operated gate that will add curb
								appeal to your property. Imagine driving up to your home or
								business, and instead of having to get out of your vehicle to
								open the gate, it automatically opens for you. In inclement
								weather, the convenience is only magnified. We’ll help you
								select the type of operation that will work best for your
								particular situation, be it electric or solar powered.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-4">
							<LazyLoadImage
								className="img-fluid"
								src={AutomaticImg2}
								alt="Automatic Gate"
							/>
						</div>
						<div className="col-md-8">
							<h4>Automatic Driveway Gates</h4>
							<p>
								Our automatic gate systems are available in wood, steel, and
								iron, as well as other materials. We offer{' '}
								<Link to="/">custom design</Link> in a wide variety of styles,
								shapes, and sizes, plus different operational methods. Our
								experts are able to help you select gate automation for almost
								any application you can imagine, for both residential and{' '}
								<Link to="/gates/services/commercial-gate">commercial</Link>{' '}
								applications.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row mb-5">
						<div className="col-md-12">
							<h4>Automatic Entry Gates</h4>
							<p>
								Automatic entrance gates are ideal for both home and business
								applications. Available with remote controls, keypads, and other
								types of keyless gate operator systems, they make life easier
								for everyone, plus provide the security you need by limiting
								access to authorized users. Whether you are looking for custom
								built or pre-fabricated, we can assist you in selecting the
								correct entry gate for your needs, plus provide any service
								required.
							</p>
							<p>
								We are a small, family owned business providing automatic
								driveway gates in Arlington, TX and the Dallas-Fort Worth
								metroplex. We are experts in design,{' '}
								<Link to="/gates/services/gate-installation">installation</Link>
								, programming and{' '}
								<Link to="/gates/services/gate-repair/">repair</Link>. If you
								would like our help in making sure you have the best solution
								for your property, and that it stays in good operating order,
								contact us today for a{' '}
								<Link to="contact-us">free estimate!</Link>
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default AutomaticGates

export const AutomaticGatesPageQuery = graphql`
	query AutomaticGatesPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
